export const environment = {
  production: true,
  api: 'https://api-abr.abrapa.com.br',
  redirecionamento_externo: 'https://sistemas.abrapa.com.br',
  api_convites: 'https://api-convites.abrapa.com.br/api/v1',
  convites: 'https://convites.abrapa.com.br/',
  sentryDSN: "",
  dataLimiteRespostaConvite: "2023-05-31",
};

